import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Box, Heading, Link, Text } from "@chakra-ui/react";
import { useFormik } from "formik";

import { submitForm } from "../fetchers";

import Form from "../components/Form";
import FormSubmitted from "../components/FormSubmitted";

const consentFormSchema = Yup.object().shape({
  email: Yup.string()
    .min(6, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 1, label: "Email" })
    .required("Required"),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 2, label: "First name" })
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 3, label: "Last name" })
    .required("Required"),
  transcriptiveTechnology: Yup.bool().meta({
    order: 4,
    label: "Transcriptive Technology"
  })
});

const ConsentsPage = () => {
  const [state, setState] = useState({
    errorMsg: "",
    loading: false,
    sent: false,
    startTs: new Date().getTime()
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      transcriptiveTechnology: ""
    },
    validationSchema: consentFormSchema,
    onSubmit: async (values) => {
      setState((s) => ({ ...s, loading: true }));
      // alert(JSON.stringify(values, null, 2));
      const result = await submitForm({
        formId: "consents-form",
        formVersion: "v1",
        formData: values,
        startTs: state.startTs,
        endTs: new Date().getTime()
      });
      if (result.success) {
        setState((s) => ({ ...s, sent: true }));
      } else if (result.status === 429) {
        setState((s) => ({
          ...s,
          errorMsg:
            "Too many requests from your network, please try again later"
        }));
      } else {
        setState((s) => ({
          ...s,
          errorMsg: "Something went wrong, please try again or reach out"
        }));
      }

      setState((s) => ({ ...s, loading: false }));
    }
  });

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const email = searchParams.get("email");
    if (email) {
      const decoded = decodeURIComponent(email);
      const cleanEmail = decoded.trim().toLowerCase();
      formik.setFieldValue("email", cleanEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (state.sent) {
    return <FormSubmitted />;
  }

  return (
    <>
      <Box sx={{ textAlign: "center", padding: 2 }}>
        <Text color="tomato" fontSize="lg">
          {state.errorMsg}
        </Text>
      </Box>
      <Box maxW={"95vw"} margin="0 auto" width="500px">
        <Heading>Transcription Consents</Heading>
        <Box height="16px" />
        <Text fontSize="medium" style={{ textIndent: "32px" }}>
          Options MD utilizes transcription technology to generate medical notes
          in real-time. Natural language processing allows the transcription
          software to analyze and dictate human conversations as they occur,
          similar to a physician's scribe. If you consent to this feature, this
          technology can be used during or after your visit when your provider
          prepares after-visit summaries. Your provider will review the
          transcription for quality assurance purposes before the notes are
          added to your medical chart.
        </Text>
        <Box height="16px" />
        <Text fontSize="medium" style={{ textIndent: "32px" }}>
          As part of the use of this technology, the video and/or audio of your
          session may be recorded. If you consent to the use of transcription
          technology, a recording of your session, along with the generated
          medical notes may be accessible to your provider for a short period
          following the conclusion of your session for the purpose of ensuring
          the accuracy of the transcription and for other treatment, payment,
          health care operations purposes or other activities of the Practice as
          otherwise set forth in the{" "}
          <Link
            href="https://optionsmd.com/practice-policies"
            style={{ textDecoration: "underline" }}
            target="_blank"
          >
            NPP
          </Link>
          . If you do not consent, the Practice will not use the transcription
          technology listed above and your session will not be recorded.
        </Text>
        <Box height="16px" />
        <Text fontSize="medium" style={{ textIndent: "32px" }}>
          In addition to the uses and disclosures set forth above, with your
          consent, the Practice may use and disclose the certain information
          collected during your session to help improve the technology and
          develop new offerings. Your name and all identifying information will
          be removed from any data before this is shared with other parties. If
          you do not consent, any video or audio recorded as part of the session
          will not be used for the purposes of product development and
          improvement. You can change your mind regarding whether we use the
          transcription technology in your sessions at any time by contacting
          the front office. If you take back your permission, the Practice will
          no longer use transcription technology and/or share the information
          collected for product development purposes. However, any action
          already taken in reliance on your consent cannot be reversed, and your
          revocation of consent will not affect those actions.
        </Text>
      </Box>
      <Form
        formik={formik}
        formProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: 400
          }
        }}
        submitButtonLabel="Submit"
        submitButtonProps={{
          colorScheme: "blue",
          sx: {
            marginTop: 2
          }
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 10
        }}
        submitLoading={state.loading}
        validationSchema={consentFormSchema}
      />
    </>
  );
};

export default ConsentsPage;
