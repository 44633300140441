import React, { useState } from "react";
import * as Yup from "yup";
import { Box, Text } from "@chakra-ui/react";
import { useFormik } from "formik";

import { submitForm } from "../fetchers";

import Form from "../components/Form";
import FormSubmitted from "../components/FormSubmitted";

const inPatientFormSchema = Yup.object().shape({
  inPatientFacilityName: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .meta({ order: 1, label: "Facility Name" }),
  inPatientFacilityAddress: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .meta({ order: 2, label: "Facility Address" }),
  inPatientFacilityEmail: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .meta({ order: 3, label: "Facility Email" }),
  inPatientFacilityFaxNumber: Yup.string()
    .min(10, "Too Short!")
    .max(12, "Too Long!")
    .meta({ order: 4, label: "Facility Fax Number" }),
  inPatientFacilityClinician: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .meta({ order: 5, label: "Clinician Name" }),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 6, label: "Legal first name" }),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 7, label: "Legal last name" }),
  date_of_birth: Yup.string()
    .required("Required")
    .meta({ order: 8, label: "Date of Birth" }),
  email: Yup.string()
    .email("Invalid email")
    .required("Required")
    .meta({ order: 9, label: "Email" }),
  phone: Yup.string()
    .min(10, "Too Short!")
    .max(11, "Too Long!")
    .meta({ order: 10, label: "phone" }),
  sexAtBirth: Yup.string()
    .required("Required")
    .oneOf(["Male", "Female"])
    .meta({
      order: 11,
      label: "Sex at birth",
      variant: "select",
      options: [
        {
          label: "Male",
          value: "Male"
        },
        {
          label: "Female",
          value: "Female"
        }
      ]
    }),
  address: Yup.string()
    .min(10, "Too Short!")
    .max(100, "Too Long!")
    .meta({ order: 12, label: "Address" }),
  state: Yup.string()
    .required("Required")
    .oneOf(["CA", "NY"])
    .meta({
      order: 13,
      variant: "select",
      label: "States",
      placeholder: "Select state",
      options: [
        {
          label: "California",
          value: "CA"
        },
        // {
        //   label: "Massachusetts",
        //   value: "MA"
        // },
        {
          label: "New York",
          value: "NY"
        }
      ]
    }),
  epocPhone: Yup.string()
    .min(10, "Too Short!")
    .max(11, "Too Long!")
    .meta({ order: 14, label: "Emergency Contact Phone" }),
  epocName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 15, label: "Emergency Contact Name" }),
  epocRelation: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 16, label: "Emergency Contact Relation" }),
  identification: Yup.string()
    .optional()
    .meta({ order: 17, label: "Identification" }),
  insuranceName: Yup.string()
    .optional()
    .meta({ order: 18, label: "Insurance Name" }),
  insuranceMemberId: Yup.string()
    .optional()
    .meta({ order: 19, label: "Insurance Member Id" }),
  insuranceGroupId: Yup.string()
    .optional()
    .meta({ order: 20, label: "Insurance Group Id" }),
  insuranceFrontUpload: Yup.string()
    .optional()
    .meta({ order: 21, label: "Insurance Front Upload" }),
  insuranceBackUpload: Yup.string()
    .optional()
    .meta({ order: 22, label: "Insurance Back Upload" })
});

const InPatientFormPage = () => {
  const [state, setState] = useState({
    errorMsg: "",
    loading: false,
    sent: false,
    startTs: new Date().getTime()
  });
  const formik = useFormik({
    initialValues: {
      inPatientFacilityName: "",
      inPatientFacilityAddress: "",
      inPatientFacilityEmail: "",
      inPatientFacilityFaxNumber: "",
      inPatientFacilityClinician: "",
      firstName: "",
      lastName: "",
      date_of_birth: "",
      email: "",
      phone: "",
      sexAtBirth: "",
      address: "",
      state: "",
      epocPhone: "",
      epocName: "",
      epocRelation: "",
      identification: "",
      insuranceName: "",
      insuranceMemberId: "",
      insuranceGroupId: "",
      insuranceFrontUpload: "",
      insuranceBackUpload: ""
    },
    validationSchema: inPatientFormSchema,
    onSubmit: async (values) => {
      setState((s) => ({ ...s, loading: true }));
      // alert(JSON.stringify(values, null, 2));
      const result = await submitForm({
        formId: "in-referral",
        formVersion: "v1",
        formData: values,
        startTs: state.startTs,
        endTs: new Date().getTime()
      });
      if (result.success) {
        setState((s) => ({ ...s, sent: true }));
      } else if (result.status === 429) {
        setState((s) => ({
          ...s,
          errorMsg:
            "Too many requests from your network, please try again later"
        }));
      } else {
        setState((s) => ({
          ...s,
          errorMsg: "Something went wrong, please try again or reach out"
        }));
      }

      setState((s) => ({ ...s, loading: false }));
    }
  });

  if (state.sent) {
    return <FormSubmitted />;
  }

  return (
    <>
      <Box bg="tomato" w="100%" p={4} color="white">
        <Text>Options Intake</Text>
      </Box>
      <Box sx={{ textAlign: "center", padding: 2 }}>
        <Text color="tomato" fontSize="lg">
          {state.errorMsg}
        </Text>
      </Box>
      <Form
        formik={formik}
        formProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: 400
          }
        }}
        submitButtonLabel="Submit"
        submitButtonProps={{
          colorScheme: "blue",
          sx: {
            marginTop: 2
          }
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 10
        }}
        submitLoading={state.loading}
        validationSchema={inPatientFormSchema}
      />
    </>
  );
};

export default InPatientFormPage;
