import React from "react";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";

// import reportWebVitals from "./reportWebVitals";

import App from "./pages/App";
import ContactFormPage from "./pages/ContactFormPage";
import InPatientReferralPage from "./pages/InPatientReferralPage";
import ConsentsPage from "./pages/ConsentsPage";
import RlTherapistReferralPage from "./pages/RlTherapistReferralPage";
import ReferAClient from "./pages/ReferAClient";

// Routes"

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/contact",
    element: <ContactFormPage />
  },
  {
    path: "/consents",
    element: <ConsentsPage />
  },
  {
    path: "/ip",
    element: <InPatientReferralPage />
  },
  {
    path: "/referring-a-client",
    element: <ReferAClient />
  },
  {
    path: "/resilience",
    element: <RlTherapistReferralPage />
  },
  {
    path: "/*",
    element: <App />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
