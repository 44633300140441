import * as Yup from "yup";
import { FieldMeta, ParsedField } from "../types/Form";

const parseFieldsFromSchema = (
  schema: Yup.ObjectSchema<Yup.AnyObject>
): ParsedField[] => {
  const fieldsCount = Object.keys(schema.fields).length;
  const fields = Object.keys(schema.fields)
    .map((fieldKey) => {
      const { meta, type } = schema.fields[fieldKey].describe() as {
        meta: FieldMeta;
        type: string;
      };
      return {
        name: fieldKey,
        meta,
        order: meta?.order || fieldsCount,
        type: meta?.variant || type,
        label: meta?.label || fieldKey
      };
    })
    .sort((fieldA, fieldB) => (fieldA.order > fieldB.order ? 1 : -1));

  return fields;
};

export { parseFieldsFromSchema };
