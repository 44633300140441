import { Box, Link, Text } from "@chakra-ui/react";
import React from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";

const FormSubmitted = () => (
  <Box>
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        padding: 10
      }}
    >
      <CheckCircleIcon color={"orange.500"} marginRight={4} />
      Submitted Successfully
      {/* <CheckCircleIcon color={"orange.500"} marginLeft={4} /> */}
    </Box>
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        padding: 10
      }}
    >
      <Text>
        <Link
          href="https://optionsmd.com"
          marginLeft={2}
          textDecoration="underline"
        >
          www.optionsmd.com
        </Link>
      </Text>
    </Box>
  </Box>
);
export default FormSubmitted;
