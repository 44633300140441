import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { PatternFormat } from "react-number-format";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  ButtonProps,
  Select,
  Box,
  BoxProps,
  Textarea
} from "@chakra-ui/react";
import * as Yup from "yup";
import { get } from "lodash";

import { parseFieldsFromSchema } from "../utils/schemaUtils";

interface FormProps extends BoxProps {
  validationSchema: Yup.ObjectSchema<Yup.AnyObject>;
  formik: ReturnType<typeof useFormik<any>>;
  formProps?: BoxProps;
  submitButtonLabel?: string;
  submitButtonProps?: ButtonProps;
  submitLoading: boolean;
}

const OptionsForm = ({
  validationSchema,
  formik,
  formProps,
  submitButtonLabel = "Submit",
  submitButtonProps,
  submitLoading,
  ...restProps
}: FormProps) => {
  const [parsedFields, setParsedFields] = useState<
    ReturnType<typeof parseFieldsFromSchema>
  >([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setParsedFields(parseFieldsFromSchema(validationSchema)), []);

  return (
    <Box {...restProps}>
      <Box as="form" onSubmit={formik.handleSubmit} {...formProps}>
        {parsedFields.map((field) => {
          if (field.type === "string") {
            if (field.name === "phone") {
              return (
                <FormControl
                  key={field.name}
                  isInvalid={Boolean(
                    get(formik.errors, field.name) &&
                      get(formik.touched, field.name)
                  )}
                >
                  <FormLabel>{field.label}</FormLabel>
                  <PatternFormat
                    customInput={Input}
                    format="(###) ###-####"
                    id={field.name}
                    name={field.name}
                    type="text"
                    onChange={formik.handleChange}
                    placeholder={field.meta?.placeholder || ""}
                    value={get(formik.values, field.name)}
                  />
                  {get(formik.errors, field.name) &&
                    get(formik.touched, field.name) && (
                      <FormErrorMessage>
                        {get(formik.errors, field.name) as string}
                      </FormErrorMessage>
                    )}
                </FormControl>
              );
            }
            return (
              <FormControl
                key={field.name}
                isInvalid={Boolean(
                  get(formik.errors, field.name) &&
                    get(formik.touched, field.name)
                )}
              >
                <FormLabel>{field.label}</FormLabel>
                <Input
                  id={field.name}
                  name={field.name}
                  type="text"
                  onChange={formik.handleChange}
                  placeholder={field.meta?.placeholder || ""}
                  value={get(formik.values, field.name)}
                />
                {get(formik.errors, field.name) &&
                  get(formik.touched, field.name) && (
                    <FormErrorMessage>
                      {get(formik.errors, field.name) as string}
                    </FormErrorMessage>
                  )}
              </FormControl>
            );
          } else if (field.type === "textarea") {
            return (
              <FormControl
                key={field.name}
                isInvalid={Boolean(
                  get(formik.errors, field.name) &&
                    get(formik.touched, field.name)
                )}
              >
                <FormLabel>{field.label}</FormLabel>
                <Textarea
                  id={field.name}
                  name={field.name}
                  onChange={formik.handleChange}
                  placeholder={field.meta?.placeholder || ""}
                  rows={4}
                  value={get(formik.values, field.name)}
                />
                {get(formik.errors, field.name) &&
                  get(formik.touched, field.name) && (
                    <FormErrorMessage>
                      {get(formik.errors, field.name) as string}
                    </FormErrorMessage>
                  )}
              </FormControl>
            );
          } else if (field.type === "select") {
            return (
              <FormControl
                key={field.name}
                isInvalid={Boolean(
                  get(formik.errors, field.name) &&
                    get(formik.touched, field.name)
                )}
              >
                <FormLabel>{field.label}</FormLabel>
                <Select
                  id={field.name}
                  name={field.name}
                  placeholder={field.meta?.placeholder || ""}
                  onChange={formik.handleChange}
                  value={get(formik.values, field.name)}
                >
                  {field.meta.options.map((selectOption) => (
                    <option key={selectOption.value} value={selectOption.value}>
                      {selectOption.label}
                    </option>
                  ))}
                </Select>
                {get(formik.errors, field.name) &&
                  get(formik.touched, field.name) && (
                    <FormErrorMessage>
                      {get(formik.errors, field.name) as string}
                    </FormErrorMessage>
                  )}
              </FormControl>
            );
          }

          return null;
        })}

        <Button
          isLoading={submitLoading}
          isDisabled={submitLoading || !formik.isValid || !formik.dirty}
          type="submit"
          {...submitButtonProps}
        >
          {submitButtonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default OptionsForm;
