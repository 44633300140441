import { envConfig } from "../common/envConfig";

export const submitForm = async ({
  formId,
  formData,
  formVersion = "v1",
  startTs,
  endTs
}: {
  formId: string;
  formData: any;
  formVersion?: string;
  startTs: number;
  endTs: number;
}) => {
  const result = await fetch(
    `${envConfig.NEXT_PUBLIC_SRVR_BASE_URL}/public/forms`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        formData,
        formFields: Object.keys(formData),
        formId,
        formVersion,
        startTs,
        endTs
      })
    }
  );

  if (result.status === 200) {
    console.log("Form submitted successfully");
    return { success: true, status: result.status };
  } else {
    console.error(
      `Error submitting form: ${result.status} ${result.statusText}`
    );
    return { success: false, status: result.status };
  }
};
