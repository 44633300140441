import React from "react";
import { Box, Link, Text } from "@chakra-ui/react";

const App = () => (
  <>
    <Box bg="tomato" w="100%" p={4} color="white">
      <Text>Options Forms</Text>
    </Box>
    <Box p={4}>
      <Text>
        Back to the main site {`->`}
        <Link
          href="https://optionsmd.com"
          marginLeft={2}
          textDecoration="underline"
        >
          www.optionsmd.com
        </Link>
      </Text>
    </Box>
  </>
);

export default App;
