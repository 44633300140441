import React, { useState } from "react";
import * as Yup from "yup";
import { Box, Text } from "@chakra-ui/react";
import { useFormik } from "formik";

import { submitForm } from "../fetchers";

import Form from "../components/Form";
import FormSubmitted from "../components/FormSubmitted";

const contactFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 1, label: "First name" }),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 2, label: "Last name" }),
  state: Yup.string()
    .required("Required")
    .oneOf(["CA", "NY", "TX"])
    .meta({
      variant: "select",
      label: "States",
      placeholder: "Select state",
      options: [
        {
          label: "California",
          value: "CA"
        },
        {
          label: "New York",
          value: "NY"
        },
        {
          label: "Texas",
          value: "TX"
        }
      ]
    })
});

const ContactFormPage = () => {
  const [state, setState] = useState({
    errorMsg: "",
    loading: false,
    sent: false,
    startTs: new Date().getTime()
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      state: ""
    },
    validationSchema: contactFormSchema,
    onSubmit: async (values) => {
      setState((s) => ({ ...s, loading: true }));
      // alert(JSON.stringify(values, null, 2));
      const result = await submitForm({
        formId: "contact-form",
        formVersion: "v1",
        formData: values,
        startTs: state.startTs,
        endTs: new Date().getTime()
      });
      if (result.success) {
        setState((s) => ({ ...s, sent: true }));
      } else if (result.status === 429) {
        setState((s) => ({
          ...s,
          errorMsg:
            "Too many requests from your network, please try again later"
        }));
      } else {
        setState((s) => ({
          ...s,
          errorMsg: "Something went wrong, please try again or reach out"
        }));
      }

      setState((s) => ({ ...s, loading: false }));
    }
  });

  if (state.sent) {
    return <FormSubmitted />;
  }

  return (
    <>
      <Box sx={{ textAlign: "center", padding: 2 }}>
        <Text color="tomato" fontSize="lg">
          {state.errorMsg}
        </Text>
      </Box>
      <Form
        formik={formik}
        formProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: 400
          }
        }}
        submitButtonLabel="Submit"
        submitButtonProps={{
          colorScheme: "blue",
          sx: {
            marginTop: 2
          }
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 10
        }}
        submitLoading={state.loading}
        validationSchema={contactFormSchema}
      />
    </>
  );
};

export default ContactFormPage;
